import { Box, Typography } from "@mui/material";
import React from "react";
import { InfoIcon } from "../Icons";

const SectionHeader = ({
  title,
  withoutInfo = false,
}: {
  title: string;
  withoutInfo?: boolean;
}) => {
  return (
    <Box className="flex justify-between items-center gap-[20px] p-[20px]">
      <Typography className="!text-md !font-medium">{title}</Typography>
      {!withoutInfo && (
        <Box className="w-[35px] h-[35px] border border-primary-blue-100 bg-primary-blue-50 flex justify-center items-center rounded-md">
          {InfoIcon}
        </Box>
      )}
    </Box>
  );
};

export default SectionHeader;
