import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { DashboardReducerAction } from "./slice";
import { call, put } from "redux-saga/effects";
import { UserService } from "../../services/dashboardServices";

const saga = createSliceSaga({
  name: "dashboard-saga-state",
  caseSagas: {
    *getUsersList() {
      try {
        yield put(DashboardReducerAction.setIsLoading(true));
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data, status }: AxiosResponse = yield call(UserService);
        if (status === 200) {
          yield put(DashboardReducerAction.setUsersList(data?.users));
        }
      } catch (error) {
        console.log("Error fetching users list");
      } finally {
        yield put(DashboardReducerAction.setIsLoading(false));
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: DashboardSagaAction,
  saga: DashboardSaga,
  name: DashboardSagaName,
} = saga;
