import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import SectionHeader from "../../SectionHeader";

const AdSpentReturn = () => {
  const data = [
    { platform: "Google", ROAS: 30, fill: "#f9a825" },
    { platform: "Facebook", ROAS: 36, fill: "#2196f3" },
    { platform: "Instagram", ROAS: 25, fill: "#e91e63" },
    { platform: "TikTok", ROAS: 18, fill: "#00bcd4" },
  ];

  const renderLegend = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 5 }}>
        {data.map((entry, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: entry.fill,
                borderRadius: "50%",
              }}
            />
            <Typography variant="body2">{entry.platform}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Return on Ad Spend (Conversions on Ad Spend)" />
      <Box
        sx={{ width: "100%", height: "100%", padding: 2 }}
        className="border-t border-t-primary-blue-100"
      >
        <ResponsiveContainer width="100%" height="80%">
          <BarChart data={data} barGap={20} barCategoryGap={40}>
            <CartesianGrid
              stroke="#ccc"
              strokeDasharray="1"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey="platform"
              tickLine={false}
              axisLine={{ stroke: "#ccc" }}
              className="text-xs text-primary-black-600"
              label={{
                value: "Platform",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              tickLine={true}
              axisLine={{ stroke: "#ccc" }}
              className="text-xs text-primary-black-600"
              label={{
                value: "ROAS",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
            />
            <Tooltip />
            <Legend content={renderLegend} />
            <Bar dataKey="ROAS" fill="#8884d8">
              {data.map((entry, index) => (
                <Bar
                  key={`bar-${index}`}
                  fill={entry.fill}
                  dataKey={entry.platform}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default AdSpentReturn;
