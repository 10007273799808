import { RouterProvider } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DashboardReducer,
  DashboardReducerName,
} from "./store/dashboard/slice";
import { DashboardSaga, DashboardSagaName } from "./store/dashboard/sagas";
import router from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { DrawerReducer, DrawerReducerName } from "./store/drawer/slice";

function App() {
  // Injecting the Reducers
  useInjectReducer({
    reducer: DashboardReducer,
    key: DashboardReducerName,
  });

  useInjectReducer({
    reducer: DrawerReducer,
    key: DrawerReducerName,
  });

  // Injecting the Sagas
  useInjectSaga({
    saga: DashboardSaga,
    key: DashboardSagaName,
  });

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
