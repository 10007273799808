import { Box, Typography } from "@mui/material";
import React from "react";
import SectionHeader from "../../SectionHeader";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const CostPerClick = () => {
  const data = [
    { platform: "Google", cost: 26.75, fill: "#f9a825" },
    { platform: "Facebook", cost: 25.09, fill: "#2196f3" },
    { platform: "Instagram", cost: 20.05, fill: "#e91e63" },
    { platform: "TikTok", cost: 5.75, fill: "#00bcd4" },
  ];

  const totalCost = data.reduce((acc, curr) => acc + curr.cost, 0);

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Cost per Click" />
      <Box className="grid grid-cols-2 p-5 items-center border-t border-t-primary-blue-100">
        <Box sx={{ position: "relative", width: "100%", height: 250 }}>
          <ResponsiveContainer>
            <PieChart>
              {/* Donut Chart */}
              <Pie
                data={data}
                dataKey="cost"
                nameKey="platform"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          {/* Total Cost in Center */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Typography className="!text-md !text-primary-black-600">
              Total
            </Typography>
            <Typography className="!text-primary-black-950 !text-2xl !font-bold">
              ${totalCost.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box className="flex flex-col gap-3 p-[20px] rounded-[10px] border border-primary-blue-100">
          {data.map((item, index) => (
            <Box
              className={`flex gap-[13px] ${
                index !== 3 && "pb-2 border-b border-b-primary-blue-100"
              }`}
            >
              <Box
                className={`w-3 h-3 rounded-full mt-1`}
                sx={{ background: item.fill }}
              />
              <Box className="flex flex-col gap-[10px]">
                <Typography className="!text-md !leading-6 !text-primary-black-600">
                  {item.platform}
                </Typography>
                <Typography className="!text-xl !font-bold">
                  ${item.cost}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CostPerClick;
