import { Box } from "@mui/material";
import AdSpentReturn from "./adSpentReturn";
import CostPerClick from "./costPerClick";

const ReturnAndCostSection = () => {
  return (
    <Box className="flex gap-[20px] w-full">
      <AdSpentReturn />
      <CostPerClick />
    </Box>
  );
};

export default ReturnAndCostSection;
