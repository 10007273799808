import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import SectionHeader from "../SectionHeader";
import { Box } from "@mui/material";

const CostPerConversionSection = () => {
  const data = [
    { date: "1st", Google: 10, Instagram: 20 },
    { date: "2nd", Google: 5, Instagram: 15 },
    { date: "3rd", Google: 15, Instagram: 10 },
    { date: "4th", Google: 10, Instagram: 25 },
    { date: "5th", Google: 35, Instagram: 30 },
    { date: "6th", Google: 0, Instagram: 50 },
    { date: "7th", Google: 20, Instagram: 40 },
    { date: "8th", Google: 25, Instagram: 35 },
    { date: "9th", Google: 15, Instagram: 20 },
    { date: "10th", Google: 20, Instagram: 30 },
    { date: "11th", Google: 30, Instagram: 45 },
    { date: "12th", Google: 40, Instagram: 50 },
    { date: "13th", Google: 35, Instagram: 45 },
    { date: "14th", Google: 20, Instagram: 30 },
    { date: "15th", Google: 15, Instagram: 25 },
    { date: "16th", Google: 10, Instagram: 20 },
    { date: "17th", Google: 5, Instagram: 15 },
    { date: "18th", Google: 10, Instagram: 20 },
    { date: "19th", Google: 15, Instagram: 25 },
    { date: "20th", Google: 20, Instagram: 30 },
    { date: "21st", Google: 25, Instagram: 35 },
    { date: "22nd", Google: 20, Instagram: 30 },
    { date: "23rd", Google: 30, Instagram: 50 },
    { date: "24th", Google: 40, Instagram: 60 },
    { date: "25th", Google: 50, Instagram: 70 },
    { date: "26th", Google: 45, Instagram: 65 },
    { date: "27th", Google: 40, Instagram: 60 },
    { date: "28th", Google: 35, Instagram: 55 },
    { date: "29th", Google: 30, Instagram: 50 },
    { date: "30th", Google: 25, Instagram: 45 },
  ];

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Cost per Conversion" />
      <ResponsiveContainer className="p-[20px] pl-0 !w-full !h-[400px]">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="1" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Google"
            stroke="#FFA500"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="Instagram"
            stroke="#FF1493"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CostPerConversionSection;
