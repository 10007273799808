import { Box } from "@mui/material";
import React from "react";
import SectionHeader from "../SectionHeader";

const ConversionsByDaySection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Conversions by Day" />
      Line Chart - 2
    </Box>
  );
};

export default ConversionsByDaySection;
