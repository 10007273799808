import { Box } from "@mui/material";
import OverviewSection from "../components/dashboard/overviewSection";
import ReturnAndCostSection from "../components/dashboard/returnAndCostSection";
import CostPerConversionSection from "../components/dashboard/costPerConversionSection";
import ConversionsByDaySection from "../components/dashboard/conversionsByDaySection";
import ChannelAndAdVisitorsSection from "../components/dashboard/channelAndAdVisitorsSection";
import AdCopiesSection from "../components/dashboard/adCopiesSection";
import InsightsSection from "../components/dashboard/insightsSection";

const Dashboard = () => {
  return (
    <Box className="flex flex-col gap-[20px]">
      <InsightsSection />
      <OverviewSection />
      <ReturnAndCostSection />
      <CostPerConversionSection />
      <ConversionsByDaySection />
      <ChannelAndAdVisitorsSection />
      <AdCopiesSection />
    </Box>
  );
};

export default Dashboard;
