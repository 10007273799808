import MainLayout from "../layout";
import Dashboard from "../pages/dashboard";
import routeConstants from "../utils/routeConstants";
import AuthGuard from "./AuthGaurds";

const MainRoutes = {
  path: "/",
  element: (
    // <AuthGuard> use '/dashboard' to access login page
    <MainLayout />
    // </AuthGuard>
  ),
  children: [
    {
      path: routeConstants.dashboard,
      element: <Dashboard />,
    },
  ],
};

export default MainRoutes;
