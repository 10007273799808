import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: false,
  userList: [],
};

const dashboard = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    // Step-3: Saving the payload in state
    setUsersList(state, action) {
      state.userList = action.payload;
    },
  },
});

export const {
  actions: DashboardReducerAction,
  name: DashboardReducerName,
  reducer: DashboardReducer,
} = dashboard;
