import React, { useState } from "react";
import {
  Box,
  FilledInputProps,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Controller, FieldValues } from "react-hook-form";
import { HidePasswordIcon, ShowPasswordIcon } from "../../assets/CustomIcons";
import { FieldConfig } from "../../types/forms";
import { RendererType } from "../../types/enums/enums";
import FormFieldLabel from "./FormFieldLabel";

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "hasIcon" && prop !== "length",
})<{ hasIcon?: boolean; length?: string }>(({ hasIcon, length }) => ({
  width: length || "100%",
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiFilledInput-root": {
    border: "1px solid #E5E5E5",
    borderRadius: "12px",
    background: "#fff",
    "&.Mui-focused, &:hover": {
      background: "#fff",
    },
  },
  "& .MuiFilledInput-input": {
    color: "#21232C !important",
    padding: "16px 20px",
  },
  "& .Mui-error": {
    marginLeft: "0px",
    "& .MuiFilledInput-input": {
      border: "1px solid #C82627",
      borderRadius: "4px",
    },
  },
}));

// CustomTextField component for rendering a text input or password field
const CustomTextField = <T extends FieldValues, U extends FieldValues = T>({
  label,
  icon,
  type,
  iconPosition,
  editable = true,
  name,
  onChange,
  placeholder,
  control,
  rules,
  errors,
  text,
  length,
  required,
}: FieldConfig<T, U> & { length?: string }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === RendererType.password;

  const handlePasswordVisibility = () => setShowPassword((show) => !show);

  const fieldInputProps: Partial<FilledInputProps> = {
    disableUnderline: true,
  };

  if (icon && iconPosition === "left") {
    fieldInputProps.startAdornment = (
      <InputAdornment position="start">{icon}</InputAdornment>
    );
  }

  if (icon && iconPosition === "right") {
    fieldInputProps.endAdornment = (
      <InputAdornment position="end">{icon}</InputAdornment>
    );
  }

  return (
    <Controller
      name={name!}
      control={control}
      rules={rules as any}
      render={({ field }) => (
        <Stack className="flex flex-col gap-[10px]">
          {label && <FormFieldLabel label={label} required={required} />}
          <Box className="flex items-center relative w-full">
            {icon && (
              <IconButton
                tabIndex={-1}
                className={`!p-0 !z-10 !absolute !ml-3 ${errors && "!-mt-6"}`}
              >
                {icon}
              </IconButton>
            )}
            <StyledTextField
              type={isPassword && !showPassword ? "password" : "text"}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              value={field.value ?? ""}
              disabled={!editable}
              placeholder={placeholder}
              error={!!errors}
              helperText={
                <span className="text-red1 mx-0 text-sm font-semibold">
                  {errors}
                </span>
              }
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange && onChange();
              }}
              hasIcon={!!icon}
              length={length} // Pass the length prop to StyledTextField
              sx={{
                "& .MuiFilledInput-input": {
                  padding: { xs: "8px 10px", sm: "16px 21px" },
                  fontSize: { xs: "14px" },
                },
              }}
            />
            {isPassword && (field.value as string)?.length > 0 && (
              <IconButton
                className={`!p-0 !z-10 !absolute right-3 bg-primary-black-950 ${
                  errors && "!-mt-6"
                }`}
                onClick={handlePasswordVisibility}
              >
                {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            )}
          </Box>
          {text && (
            <Typography className="!text-grey1 !text-xs !font-medium">
              {text}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default CustomTextField;
