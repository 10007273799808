import { Box, Typography } from "@mui/material";
import React from "react";
import {
  GrowthChartSmallGreenIcon,
  GrowthChartSmallYellowIcon,
} from "../Icons";
import CustomChip from "../common/CustomChip";
import { InsightCardsProps } from "../../types/dashboardTypes";

const InsightCards = ({
  title,
  quantity,
  icon,
  growth,
  status,
}: InsightCardsProps) => {
  const getIcons = () => {
    switch (status) {
      case "success":
        return <GrowthChartSmallGreenIcon />;
      case "warning":
        return <GrowthChartSmallYellowIcon />;
      default:
        break;
    }
  };

  return (
    <Box className="p-[20px] flex flex-col gap-[30px]  border-r border-r-primary-blue-100 border-t border-t-primary-blue-100  ">
      <Box className="flex justify-between items-center">
        <Box className="flex flex-col gap-[8px] min-w-">
          <Typography className="!text-lg text-primary-blue-800 text-balance">
            {title}
          </Typography>
          <Typography className="!font-bold !text-[32px] text-primary-blue-950">
            {quantity}
          </Typography>
        </Box>
        <Box className="h-[72px] w-[72px] flex justify-center items-center rounded-full bg-primary-blue-50 border border-primary-blue-200">
          {icon}
        </Box>
      </Box>
      <Box className="flex gap-[6px] items-center">
        <CustomChip color={status} label={growth} icon={getIcons()} />
        <Typography className="!font-medium !text-xs text-primary-blue-800">
          Vs Previous
        </Typography>
      </Box>
    </Box>
  );
};

export default InsightCards;

// [&:last-child]:border-none
// [&:nth-child(even)]:border-none
