import { Box } from "@mui/material";
import React from "react";
import InsightCards from "./InsightCards";
import { CursorIcon, DollarIcon, GrowthChartBigBlueIcon } from "../Icons";
import { colorType } from "../../types/common";

const InsightCardsSection = () => {
  const insightCardsData = [
    {
      status: "success",
      title: "Impressions",
      quantity: "69,273",
      icon: <GrowthChartBigBlueIcon />,
      growth: "1.75 %",
    },
    {
      status: "warning",
      title: "Clicks",
      quantity: "748",
      icon: <CursorIcon />,
      growth: "1.75 %",
    },
    {
      status: "success",
      title: "Conversions",
      quantity: "12,147",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
    {
      status: "success",
      title: "CTR",
      quantity: "0.38 %",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
    {
      status: "success",
      title: "Cost",
      quantity: "$ 1.5K",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
    {
      status: "warning",
      title: "Cost per Conversion",
      quantity: "$ 5",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
    {
      status: "warning",
      title: "Avg. CPC",
      quantity: "$ 120",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
    {
      status: "success",
      title: "Conversions on Ad Spend",
      quantity: "1.02",
      icon: <DollarIcon />,
      growth: "1.75 %",
    },
  ];
  return (
    <Box className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {insightCardsData.map(({ status, title, quantity, icon, growth }) => (
        <InsightCards
          status={status as colorType}
          title={title}
          quantity={quantity}
          icon={icon}
          growth={growth}
        />
      ))}
    </Box>
  );
};

export default InsightCardsSection;
