import { Avatar, AvatarGroup, Box } from "@mui/material";
import { FacebookIcon, GoogleIcon, InstagramIcon, TikTokIcon } from "../Icons";

const SocialsHeader = () => {
  return (
    <Box className="flex justify-start items-center gap-[20px] p-[20px]">
      <a href="/">
        <GoogleIcon />
      </a>
      <a href="/">
        <FacebookIcon />
      </a>
      <a href="/">
        <InstagramIcon />
      </a>
      <a href="/">
        <TikTokIcon />
      </a>
    </Box>
  );
};

export const SocialIconGroup = () => (
  <AvatarGroup>
    <Avatar className="!bg-primary-blue-50">{GoogleIcon()}</Avatar>
    <Avatar className="!bg-primary-blue-50">{FacebookIcon()}</Avatar>
    <Avatar className="!bg-primary-blue-50">{InstagramIcon()}</Avatar>
    <Avatar className="!bg-primary-blue-50">{TikTokIcon()}</Avatar>
  </AvatarGroup>
);

export default SocialsHeader;
