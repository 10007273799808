import React from "react";
import SectionHeader from "../SectionHeader";
import { Box } from "@mui/material";

const AdCopiesSection = () => {
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Ad Copies - Top Creative" withoutInfo />
      <Box className="p-[20px] flex flex-col gap-5 md:grid md:grid-cols-4">
        AdCopiesCards
      </Box>
    </Box>
  );
};

export default AdCopiesSection;
