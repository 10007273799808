import { Box } from "@mui/material";
import React from "react";
import SectionHeader from "../SectionHeader";
import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend } from "recharts";

const OverviewSection = () => {
  const data = [
    {
      value: 1000,
      name: "13.5k",
    },
    {
      value: 1000,
      name: "1.2k",
    },
    {
      value: 1000,
      name: "47",
    },
    {
      value: 1000,
      name: "26",
    },
    {
      value: 1000,
      name: "1.66k",
    },
  ];
  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 flex flex-col">
      <SectionHeader title="Overview" />
      <BarChart
        width={1000}
        height={250}
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </Box>
  );
};

export default OverviewSection;
