import { Box, Drawer, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ArrowRightIcon,
  BarGraphIcon,
  BookIcon,
  ChatsIcon,
  DashboardIcon,
  InfoIcon,
  ListIcon,
  MegaphoneIcon,
  NavigationButtonRectangle,
  PlayIcon,
  ShieldIcon,
  TreeIcon,
} from "../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import { drawerOpenSelector } from "../../store/drawer/selector";
import { NavLink } from "react-router-dom";

const buttons = [
  { icon: DashboardIcon, label: "Dashboard", link: "/" },
  { icon: ListIcon, label: "Conversions", link: "/conversions" },
  { icon: MegaphoneIcon, label: "Campaigns", link: "/campaigns" },
  { icon: PlayIcon, label: "Ad Copies", link: "/ad-copies" },
  { icon: TreeIcon, label: "Manage Data Sources", link: "/data-source" },
  { icon: BarGraphIcon, label: "Reports", link: "/reports" },
  {
    icon: BookIcon,
    label: "Learn about Trial Partners",
    link: "/learn-about-us",
  },
  { icon: ChatsIcon, label: "Send Feedback", link: "/send-feedback" },
  { icon: InfoIcon, label: "About Us", link: "/about-us" },
  { icon: ShieldIcon, label: "Privacy Policy", link: "/privacy-policy" },
];

const Sidebar = () => {
  const dispatch = useDispatch();

  const isDrawerOpen = useSelector(drawerOpenSelector);

  return (
    <Box className="sidebar-container md:fixed md:bg-white no-scrollbar">
      <Drawer
        open={isDrawerOpen}
        onClose={() => dispatch(DrawerReducerAction.setDrawerOpen(false))}
        className="block md:hidden"
      >
        <MenuItems />
      </Drawer>
      <Box>
        <MenuItems />
      </Box>
    </Box>
  );
};

const MenuItems = () => {
  const [selectedButton, setSelectedButton] = useState(0);

  const handleButtonClick = (index: any) => {
    setSelectedButton(index);
  };

  return (
    <Box className="px-[20px] py-[25px] flex flex-col gap-[40px] no-scrollbar">
      <Box className="flex justify-center items-center cursor-pointer">
        <img src={require("../../assets/logo.png")} alt="logo" className="" />
      </Box>
      <Box className="flex flex-col gap-[30px]">
        {/* my profile section */}
        <Box className="flex flex-col gap-[10px]">
          <Typography className="!text-sm !font-normal !text-primary-black-600 uppercase">
            My Profile
          </Typography>
          <Box className="px-[12px] py-[10px] rounded-[6px] border border-primary-blue-100 gap-[10px] bg-primary-blue-50 flex justify-between items-center">
            <Box className="flex gap-[8px] items-center">
              <img
                src={require("../../assets/profilepic.png")}
                alt="logo"
                className="w-7 h-7 rounded-full"
              />
              <Box className="flex flex-col items-start">
                <Typography className="!text-sm font-normal text-primary-black-950 ">
                  Ronald Richards
                </Typography>
                <Typography className="!text-xs font-normal text-primary-blue-800 ">
                  ronald12@gmail.com
                </Typography>
              </Box>
            </Box>
            {ArrowRightIcon}
          </Box>
        </Box>

        {/* Navigation Buttons */}
        <Box className="flex flex-col gap-[10px]">
          {buttons.map(({ icon, label, link }, index) => (
            <>
              {index === 8 && (
                <span
                  key="separator"
                  className="my-[20px] border border-[#E9ECF2]"
                />
              )}
              <Box className="flex">
                {selectedButton === index && NavigationButtonRectangle}
                <NavLink
                  key={index}
                  to={link}
                  onClick={() => handleButtonClick(index)}
                  className={`px-[12px] py-[10px] rounded-[6px] gap-[10px] flex-1 justify-between cursor-pointer ${
                    selectedButton === index
                      ? "border border-[#E9ECF2] bg-[#F4F5F8]"
                      : "transparent"
                  }`}
                >
                  <Box className="flex gap-[6px] items-center">
                    {icon}
                    <Box className="flex flex-col items-start">
                      <Typography className="!text-sm font-normal text-primary-black-950 ">
                        {label}
                      </Typography>
                    </Box>
                  </Box>
                </NavLink>
              </Box>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
