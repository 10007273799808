import { Box } from "@mui/material";
import React from "react";
import SectionHeader from "../SectionHeader";

const ChannelAndAdVisitorsSection = () => {
  return (
    <Box className="flex gap-[20px] w-full">
      <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
        <SectionHeader title="Top Advertising channel by Conversions" />
        Pie Chart
      </Box>
      <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
        <SectionHeader title="Ad Visitors across Devices" />
        Multi Progress Chart
      </Box>
    </Box>
  );
};

export default ChannelAndAdVisitorsSection;
