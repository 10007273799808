import { Box, Button, Typography } from "@mui/material";
import CustomTextField from "../../components/formControls/CustomTextField";
import { RendererType } from "../../types/enums/enums";
import { SubmitHandler, useForm } from "react-hook-form";
type LoginFormFields = {
  email: string;
  password: string;
};

const Login = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormFields>();

  const onSubmit: SubmitHandler<LoginFormFields> = (data) => {
    console.log("Form submitted payload:", data);
  };
  return (
    <Box className="login-container">
      <Box className="absolute top-0 left-0 bg-primary-blue-200 rounded-b-[80px] h-1/2 w-full">
        <Box className="py-[38px] px-[120px]">
          <img
            src={require("../../assets/logo.png")}
            alt="logo"
            className="sm:w-[272px] sm:h-[45px]"
          />
        </Box>
      </Box>
      <Box className="flex flex-col w-[80vw] sm:w-[60vw] rounded-[30px] bg-white z-10">
        <Box className="flex justify-between w-full items-center p-[15px] sm:p-[30px] border-b border-gray-100">
          <Box className="flex flex-col gap-[6px]">
            <Typography className="!text-[28px] sm:!text-[32px] !font-semibold">
              Login
            </Typography>
            <Typography className="!text-[14px] sm:!text-[20px] text-primary-black-600">
              Welcome back to TrialPartners!
            </Typography>
          </Box>
          <Button
            variant="outlined"
            className="!border w-[120px] sm:w-[190px] !border-primary-blue-950 sm:!px-[10px] sm:!py-[16px] sm:!text-[16px] !font-medium !text-primary-blue-950 !rounded-[12px] !normal-case"
          >
            Sign Up
          </Button>
        </Box>
        <Box className="flex justify-between w-full items-center p-[15px] sm:p-[30px]">
          {/* Form */}
          <form className="flex flex-col gap-[10px] sm:gap-[30px] w-full">
            <Box className="flex flex-col gap-[8px] sm:gap-[15px]">
              <Box className="flex flex-col gap-[10px] sm:gap-[30px]">
                <Box className="">
                  <CustomTextField
                    label="Email"
                    name="email"
                    control={control}
                    type={RendererType.string}
                    placeholder="Enter Email"
                    errors={errors.email?.message?.toString()}
                    rules={{
                      required: "Email is Required",
                    }}
                  />
                </Box>
                <Box className="flex flex-col ">
                  <Box>
                    <CustomTextField
                      label="Password"
                      name="password"
                      control={control}
                      type={RendererType.password}
                      placeholder="Enter Password"
                      errors={errors.password?.message?.toString()}
                      rules={{
                        required: "Password is Required",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="flex justify-end">
                <Typography className="font-bold sm:!font-semibold !text-sm sm:text-base cursor-pointer">
                  Forgot Password?
                </Typography>
              </Box>
            </Box>

            <Box>
              <Button
                className="!border w-full sm:w-[252px] !bg-primary-blue-950 !gap-[10px] sm:!px-[10px] sm:!py-[16px] !text-[16px] !font-medium !text-white !rounded-[12px] !normal-case"
                variant="contained"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
