import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { BellIcon, PlusIcon } from "../../components/Icons";
import BasicSelect from "../../components/formControls/Select";
import { useDispatch } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const dispatch = useDispatch();

  const openDrawer = () => {
    dispatch(DrawerReducerAction.setDrawerOpen(true));
  };

  return (
    <Box className="header-container">
      <Box className="flex items-center gap-4">
        <Box onClick={openDrawer} className="cursor-pointer md:hidden">
          <MenuIcon />
        </Box>
        <Typography className="!text-xl !font-medium">Dashboard</Typography>
      </Box>
      <Box className="gap-3 hidden md:flex">
        <Box className="">
          <BasicSelect title="Campaign" />
        </Box>
        <Box>
          <IconButton
            sx={{
              paddingX: "12px",
              paddingY: "10px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {PlusIcon}
            <Typography className="text-sm font-normal text-primary-black-950 ">
              Generate report
            </Typography>
          </IconButton>
        </Box>
        <Box>
          <BasicSelect title="Last 30 days" />
        </Box>
        <Box>
          <IconButton
            sx={{
              paddingX: "12px",
              paddingY: "10px",
              borderRadius: "6px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#F4F5F8",
            }}
          >
            {BellIcon}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
