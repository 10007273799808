import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function BasicSelect({
  title,
  icon,
}: {
  title: any;
  icon?: any;
}) {
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 136, height: "100%" }}>
      <FormControl fullWidth>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "6px",
            "& .MuiSelect-select": {
              height: "100%",
            },
            "& .MuiOutlinedInput-input": {
              paddingX: "12px !important",
              paddingY: "10px !important",
              backgroundColor: "#F4F5F8",
            },
          }}
        >
          <MenuItem value="">
            <div className="!flex !gap-2 pr-6">
              {icon} {title}
            </div>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
